"use client";

import WentWrongError from "@/components/shared/WentWrongError";

type ErrorProps = {
  error: Error;
  reset: () => void;
};

export default function Error({ error, reset }: ErrorProps) {
  return (
    <div className="flex flex-col items-center justify-center text-center py-10">
      <WentWrongError error={error} reset={reset} />
    </div>
  );
}
